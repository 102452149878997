<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle class="justify-space-between">
            <span>Редактирование курса</span>
            <div>
              <VBtn
                depressed
                color="primary"
                :to="{
                  name : Names.R_CONTENT_STORAGE_COURSE_TOPICS_EDIT,
                  query : { ...$route.query }
                }"
              >
                <VIcon left>
                  fal fa-edit
                </VIcon>

                Редактировать топики
              </VBtn>
            </div>
          </VCardTitle>
          <VDivider />
          <VCardText>
            <VTextField
              v-model="title"
              label="Название курса"
              filled
            />
            <VTextarea
              v-model="description"
              label="Описание курса"
              filled
            />
            <VExpansionPanels
              accordion
              flat
            >
              <VExpansionPanel>
                <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                <VExpansionPanelContent>
                  <code class="elevation-0 d-block">{{ courseMeta }}</code>
                </VExpansionPanelContent>
              </VExpansionPanel>
            </VExpansionPanels>
          </VCardText>
          <VCardActions class="mt-2 px-4">
            <VBtn
              depressed
              color="primary"
              large
              @click="updateCourse"
            >
              Сохранить курс
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { queryParse } from '@front.backoffice/common';

export default {
  name: 'CourseEdit',
  inject: ['Names'],
  data() {
    return {
      id: '',
      title: '',
      description: '',
      cachedCourse: {},
    };
  },
  computed: {
    courseMeta() {
      const { id } = this;
      const { labels } = this.cachedCourse;

      return {
        id,
        labels,
      };
    },
  },
  created() {
    if (!this.$route.query.labels) {
      this.$di.notify.snackError('Отсутствуют метки в адресе');
      return;
    }
    this.getCourse();
  },
  methods: {
    updateCourse() {
      const topicsIds = this.cachedCourse.topics.map((topic) => {
        // Note: В топике вместо набора контента может ничего не придти (это будет ключ all)
        if (!topic.items) {
          return topic;
        }

        return {
          ...topic,
          items: topic.items.map((item) => item.id),
        };
      });

      const payload = {
        labels: this.cachedCourse.labels,
        topics: topicsIds,
        title: this.title,
        description: this.description,
      };

      this.$di.api.ContentStorage
        .courseItemEdit(payload)
        .then(() => {
          this.$di.notify.snackSuccess('Курс успешно изменен');
        })
        .catch(this.$di.notify.errorHandler);
    },
    getCourse() {
      const { labels: routeLabels } = queryParse(this.$route.query.labels);

      const payload = {
        labels: routeLabels,
      };

      /**
       * TODO: разобраться совместно с бэкендом с этим бардаком
       * Note: Делаем первый запрос для получения topicKeys
       * Это понадобится для запроса всего контента
       */
      this.$di.api.ContentStorage
        .courseItemGet(payload)
        .then((response) => {
          payload.topics = response.topicKeys;

          /**
           * Note: Делаем второй запрос, чтобы забрать ВЕСЬ контент
           * потому что если бэкенду не прислать его обратно - он удалит весь контент
           */
          this.$di.api.ContentStorage
            .courseItemGet(payload)
            .then((fullCourse) => {
              const { topics } = fullCourse;
              const {
                id, title, description, labels,
              } = fullCourse.course;

              this.id = id;
              this.title = title;
              this.description = description;
              this.cachedCourse = {
                title,
                labels,
                topics,
                description,
              };
            })
            .catch(this.$di.notify.errorHandler);
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
